.asu-nav.navbar{
    padding-top: 0px;
    padding-bottom: 0px;
    
}

.asu-nav.navbar .navbar-nav a{
    padding-top:1rem;
    padding-bottom:1rem;
    color: #ededed
}

.asu-nav.navbar .navbar-nav a:hover,
.asu-nav.navbar .navbar-nav a.active{
    background-color: #080808;
    color:#ffb130 
}

